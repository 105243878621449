// src/pages/Students.js

import React, { useEffect, useState } from 'react';
import api from '../utils/api';
import { Container, Paper, Typography, List, ListItem, ListItemText, ListItemIcon, IconButton, Button, Avatar } from '@mui/material';
import { Edit, Delete, CreditCard } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import ConfirmDialog from '../components/ConfirmDialog';

const Students = () => {
  const [students, setStudents] = useState([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const response = await api.get('/students');
        setStudents(response.data);
      } catch (error) {
        console.error('Error fetching students:', error);
      }
    };

    fetchStudents();
  }, []);

  const handleDeleteClick = (payment) => {
    setIdToDelete(payment);
    setConfirmOpen(true);
  };

  const deleteStudent = async () => {
    if (idToDelete) {
      try {
        await api.post(`/students/${idToDelete}/delete`);
        setStudents(students.filter(student => student.id !== idToDelete));
        setConfirmOpen(false);
      } catch (error) {
        console.error('Error deleting student:', error);
      }
    }
  };

  return (
    <Container>
      <Paper elevation={3} style={{ padding: '1em' }}>
        <Typography variant="h5" gutterBottom>
          Students
        </Typography>
        <Button variant="contained" color="primary" component={Link} to="/add-student" fullWidth>
          Create New Student
        </Button>
        <List>
          {students.map((student) => (
            <ListItem key={student.id}>
              <ListItemIcon>
                <Avatar src={`${process.env.REACT_APP_API_BASE_URL}/uploads/photos/t-${student.photo}`}/>
              </ListItemIcon>
              <ListItemText primary={`${student.firstName} ${student.lastName}`} />
              <IconButton component={Link} to={`/payments/student/${student.id}`}>
                <CreditCard />
              </IconButton>
              <IconButton component={Link} to={`/edit-student/${student.id}`}>
                <Edit />
              </IconButton>
              <IconButton onClick={() => handleDeleteClick(student.id)}>
                <Delete />
              </IconButton>
            </ListItem>
          ))}
        </List>
      </Paper>

      <ConfirmDialog
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        onConfirm={deleteStudent}
        title="Delete Student"
        description="Are you sure you want to delete this student?"
      />
    </Container>
  );
};

export default Students;
