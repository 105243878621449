// src/pages/Settlements.js

import React, { useState, useEffect } from 'react';
import { Container, Paper, Typography } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import api from '../utils/api';
import dayjs from 'dayjs';

const Reports = () => {
  const [report, setReport] = useState([]);

useEffect(() => {
    const fetchPayments = async () => {
      try {
        const response = await api.get('/payments/');
        const reportData = {};
        response.data.forEach(entry => {
            if (entry.representing.startsWith('Ab')) {
                const month = dayjs(entry.date).format('MMM/YY');
                if (!reportData[month]) {
                    reportData[month] = 0;
                }
                reportData[month] += entry.amount;
            }
        });
        Object.keys(reportData).forEach(month => {
            report.push({
                month,
                amount: reportData[month]
            })
        });
        setReport(report);
      } catch (error) {
        console.error('Error fetching payments:', error);
      }
    };

    fetchPayments();
  }, []);

const chartSetting = {
    xAxis: [
      {
        label: 'Abonamente',
      },
    ],
    width: 600,
    height: 400,
  };

  return (
    <Container>
      <Paper elevation={3} style={{ padding: '1em' }}>
        <Typography variant="h5" gutterBottom>
          Reports
        </Typography>
        <BarChart
                dataset={report}
                yAxis={[{ scaleType: 'band', dataKey: 'month' }]}
                series={[{ dataKey: 'amount', label: 'Incasari abonamente' }]}
                layout="horizontal"
                slotProps={{ legend: { hidden: true } }}
                {...chartSetting}
        />
      </Paper>
    </Container>
  );
};

export default Reports;
